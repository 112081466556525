#mobileHeader {
	display: none;

	.mobileHeaderAd {
		position: relative;
		background: #111111;
		text-align: center;
		max-height: 47px;

		img {
			width: auto;
			max-width: 728px;
			max-height: 47px;
		}

		@media only screen and (max-width: 380px) { //new
				img {
					width: 100vw !important;
				}

		}

		.lbOn.lbox_bound { display: none; }

	}

	@media only screen and (max-width: 380px) { //new
			.mobileHeaderAd {
				background: #FFFFFF !important;
			}
	}

	.mobileHeaderSponsor {
		height: 40px;
		background: #111111;
		position: relative;
		display: none;

		.wrapIt {
			height: 100%;
			display: inline-block;
		}

		.pageElement {
			height: 100%;
			text-align: center;

			img {
				height: 100%;
				display: inline-block;
			}

			.cutline {
				width: auto;
    		float: left;
    		color: white;
    		padding: 11px 5px;
			}

		}

	}

}

@media screen and (max-width: 1024px) {

	#mobileHeader { display: block; }

}
